/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-08-04 20:58:27
 *@email: casudin.dev@gmail.com
 */
import VueApexCharts from 'vue3-apexcharts';

export const install = ({ app }) => {
  app.use(VueApexCharts, {});
};
