import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/keuangan',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Uang Settings',
      requiresAuth: true,
      Permission: 'read_keuangan'
    },
    children: [
      {
        path: 'dashboard',
        name: 'keuangan.dashboard',
        component: () => import('@/views/Keuangan/DashV.vue'),
        meta: {
          title: 'Dashboard',
          requiresAuth: true
        }
      },
      {
        path: 'income',
        name: 'income.index',
        component: () => import('@/views/Keuangan/Income/IndexV.vue'),
        meta: {
          title: 'Income',
          requiresAuth: true
        }
      },
      {
        path: 'payment-online',
        name: 'payment.index',
        component: () => import('@/views/Keuangan/Pg/IndexV.vue'),
        meta: {
          title: 'Payment Online',
          requiresAuth: true
        }
      },
      {
        path: 'jurnal',
        name: 'jurnal.index',
        component: () => import('@/views/Keuangan/Jurnal/Index.vue'),
        meta: {
          title: 'Jurnal',
          requiresAuth: true
        }
      },
      {
        path: 'transaksi',
        name: 'transaksi.index',
        component: () => import('@/views/Keuangan/Transaksi/IndexV.vue'),
        meta: {
          title: 'Transaksi',
          requiresAuth: true,
          Permission: 'read_transaksi_keuangan'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
