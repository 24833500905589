import { createRouter, createWebHistory } from 'vue-router';
import fronsiteRoute from './frontsite';
import dashboard from './dashboard';
import nProgress from 'nprogress';
import authGuard from '@/middleware/guard';
import nasRoute from './radius';
import customerRoute from './customer';
import voucherRoute from './voucher';
import invRouter from './invoices';
import settingRoute from './settings';
import keuangan from './keuangan';
import imports from './imports';
import sessionuser from './session';
import errorRoute from './errorRoute';
import oltRoute from './olt';
import wa from './whatsapp';
import affiliate from './affiliate';
import publicRoute from './public';
import odpRoute from './odp';
import inventoryRouter from './inventory';
import reportRoute from './reports';
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  activeClass: 'active',
  routes: [
    ...fronsiteRoute.options.routes,
    ...dashboard.options.routes,
    ...nasRoute.options.routes,
    ...customerRoute.options.routes,
    ...voucherRoute.options.routes,
    ...invRouter.options.routes,
    ...settingRoute.options.routes,
    ...keuangan.options.routes,
    ...imports.options.routes,
    ...sessionuser.options.routes,
    ...errorRoute.options.routes,
    ...oltRoute.options.routes,
    ...wa.options.routes,
    ...affiliate.options.routes,
    ...odpRoute.options.routes,
    ...publicRoute.options.routes,
    ...inventoryRouter.options.routes,
    ...reportRoute.options.routes
  ]
});

export default router;

router.beforeEach(authGuard);

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  nProgress.done();
  window.scrollTo(0, 0);
});
