import Cookies from 'js-cookie';
import { ref } from 'vue';

const menuPermissions = ref(Cookies.get('abilities') ? JSON.parse(Cookies.get('abilities')) : []);

export const permissionDirective = {
  async mounted(el, binding) {
    const permissionStore = menuPermissions.value;
    const { value } = binding;

    // Pastikan abilities sudah dimuat dari API atau cache

    // Cek apakah user punya permission
    const hasPermission = permissionStore.includes(value);

    if (!hasPermission && value) {
      el.remove(); // Hapus elemen jika tidak ada permission
    }
  },

  async updated(el, binding) {
    const permissionStore = menuPermissions.value;
    const { value } = binding;

    const hasPermission = permissionStore.includes(value);

    if (!hasPermission && value) {
      el.remove(); // Hapus elemen jika permission tidak valid
    }
  }
};
