/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-12-08 01:51:31
 * @Email: casudin.dev@gmail.com
 */

import { permissionDirective } from '@/directive/permissionDirective';

export const install = ({ app }) => {
  app.directive('can', permissionDirective);
};
