import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/reports',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Reports',
      requiresAuth: true,
      Permissions: 'read_report'
    },
    children: [
      {
        path: 'branchs',
        name: 'report.branch.index',
        component: () => import('@/views/Reports/Branchs/IndexV.vue'),
        meta: {
          title: 'Report Branchs Performance',
          requiresAuth: true,
          Permissions: 'read_report_branch'
        }
      },
      {
        path: 'branchs/:branchId',
        name: 'report.branch.show',
        component: () => import('@/views/Reports/Branchs/ShowV.vue'),
        meta: {
          title: 'Report Branchs Performance',
          requiresAuth: true,
          Permissions: 'read_report_branch'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
