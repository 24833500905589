/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-12-08 01:57:29
 * @Email: casudin.dev@gmail.com
 */
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/inventory',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Inventory',
      requiresAuth: true,
      Permissions: 'read_inventory'
    },
    children: [
      {
        path: 'transaction',
        name: 'inventory.transaction',
        component: () => import('@/views/Inventory/Transaction/Index.vue'),
        meta: {
          title: 'Transaction',
          requiresAuth: true,
          Permissions: 'read_inventory'
        }
      },
      {
        path: 'transaction/create',
        name: 'inventory.transaction.create',
        component: () => import('@/views/Inventory/Transaction/Create.vue'),
        meta: {
          title: 'Create Transaction',
          requiresAuth: true,
          Permissions: 'create_inventory_transaction'
        }
      },
      {
        path: 'transaction/:transactionId',
        name: 'inventory.transaction.show',
        component: () => import('@/views/Inventory/Transaction/Show.vue'),
        meta: {
          title: 'Show Transaction',
          requiresAuth: true,
          Permissions: 'read_inventory'
        }
      },
      {
        path: 'items',
        name: 'inventory.items',
        component: () => import('@/views/Inventory/Items/Index.vue'),
        meta: {
          title: 'Items',
          requiresAuth: true,
          Permissions: 'read_inventory_item'
        }
      }
    ]
  }
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  activeClass: 'active',
  routes
});

export default router;
