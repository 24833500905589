/**
 * javascript comment
 * @Author: Casudin
 * @Date: 2024-07-15 22:58:21
 *@email: casudin.dev@gmail.com
 */
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/settings',

    component: () => import('@/layouts/BaseV.vue'),
    meta: {
      title: 'Home Settings',
      requiresAuth: true
    },
    children: [
      {
        path: 'emails',
        name: 'setting.emails',
        component: () => import('@/views/Settings/EmailV.vue'),
        meta: {
          title: 'Emails',
          requiresAuth: true,
          Permissions: 'read_email'
        }
      },
      {
        path: 'general',
        name: 'setting.umum',
        component: () => import('@/views/Settings/UmumV.vue'),
        meta: {
          title: 'General',
          requiresAuth: true,
          Permissions: 'read_umum'
        }
      },
      {
        path: 'voucher',
        name: 'setting.voucher',
        component: () => import('@/views/Settings/Templates/Voucher/IndexV.vue'),
        meta: {
          title: 'Voucher',
          requiresAuth: true,
          Permissions: 'read_voucher_templates'
        }
      },
      {
        path: 'voucher/add',
        name: 'setting.voucher.add',
        component: () => import('@/views/Settings/Templates/Voucher/AddV.vue'),
        meta: {
          title: 'Voucher',
          requiresAuth: true,
          Permissions: 'create_voucher_templates'
        }
      },
      //templateId
      {
        path: 'voucher/:templateId',
        name: 'setting.voucher.template',
        component: () => import('@/views/Settings/Templates/Voucher/EditV.vue'),
        meta: {
          title: 'Voucher',
          requiresAuth: true,
          Permissions: 'update_voucher_templates'
        }
      },
      // priview
      {
        path: 'voucher/preview/:templateId',
        name: 'setting.voucher.preview',
        component: () => import('@/views/Settings/Templates/Voucher/PreviewV.vue'),
        meta: {
          title: 'Voucher Priview',
          requiresAuth: true,
          Permissions: 'read_voucher_templates'
        }
      },
      {
        path: 'whatsapp',
        name: 'setting.whatsapp',
        component: () => import('@/views/Settings/WhatsappV.vue'),
        meta: {
          title: 'Whatsapp',
          requiresAuth: true,
          Permissions: 'read_wahatsapp_gateway'
        }
      },
      {
        path: 'template-whatsapp',
        name: 'setting.whatsapp.template',
        component: () => import('@/views/Settings/Templates/Whatsapps/IndexV.vue'),
        meta: {
          title: 'Whatsapp Template',
          requiresAuth: true,
          Permissions: 'read_wahatsapp_templates'
        }
      },
      {
        path: 'template-whatsapp/add',
        name: 'setting.whatsapp.template.add',
        component: () => import('@/views/Settings/Templates/Whatsapps/AddV.vue'),
        meta: {
          title: 'Whatsapp Template',
          requiresAuth: true,
          Permissions: 'create_wahatsapp_templates'
        }
      },
      {
        path: 'template-whatsapp/edit/:waId',
        name: 'setting.whatsapp.template.edit',
        component: () => import('@/views/Settings/Templates/Whatsapps/EditV.vue'),
        meta: {
          title: 'Whatsapp Template',
          requiresAuth: true,
          Permissions: 'update_wahatsapp_templates'
        }
      },
      {
        path: 'template-whatsapp/group/:waId',
        name: 'setting.whatsapp.template.group',
        component: () => import('@/views/Settings/Templates/Whatsapps/EditV.vue'),
        meta: {
          title: 'Whatsapp Template',
          requiresAuth: true,
          Permissions: 'read_wahatsapp_templates'
        }
      },
      {
        path: 'payment',
        name: 'setting.payment',
        component: () => import('@/views/Settings/PaymentV.vue'),
        meta: {
          title: 'Payment',
          requiresAuth: true
        }
      },
      {
        path: 'roles',
        name: 'setting.roles',
        component: () => import('@/views/Settings/RolesV.vue'),
        meta: {
          title: 'Roles Settings',
          requiresAuth: true,
          Permissions: 'read_user'
        }
      },
      //branch
      {
        path: 'branch',
        name: 'setting.branch',
        component: () => import('@/views/Settings/BranchV.vue'),
        meta: {
          title: 'Branch Settings',
          requiresAuth: true,
          Permissions: 'read_branch'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  activeClass: 'active',
  routes
});

export default router;
